import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../../static/images/Clean_logo.svg"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "privacy" } }) {
      html
      frontmatter {
        title
        slug
        lastUpdated
      }
    }
  }
`

const Privacy = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark
  return (
    <Layout
      className={"privacy-page"}
      style={{
        backgroundImage:
          "linear-gradient(to right bottom, #99ba44, #a1c04b, #a8c651, #b0cc58, #b8d25e, #b8d25e, #b8d25e, #b8d25e, #b0cc58, #a8c651, #a1c04b, #99ba44)",
      }}
    >
      <SEO title="Privacy Policy" />
      <header>
        <div className="logo text-center">
          <Link to="/">
            <Logo
              className="my-4 my-md-6"
              style={{
                width: 260,
              }}
            />
          </Link>
        </div>
      </header>
      <main
        style={{
          display: "grid",
          justifyContent: "center",
          paddingBottom: "3rem",
        }}
      >
        <div
          className="privacy-text"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </main>
    </Layout>
  )
}

export default Privacy
